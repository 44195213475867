import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Section from 'gatsby-theme-psg/src/components/layout/section/Section'
import Header from '../components/Header'
import Lineup from 'gatsby-theme-psg/src/components/event/lineup/Lineup'
import Gallery from 'gatsby-theme-psg/src/components/gallery/Gallery'
import EventContainer from 'gatsby-theme-psg/src/components/event/EventContainer'
import Heading from 'gatsby-theme-psg/src/components/layout/section/Heading'
import Tickets from 'gatsby-theme-psg/src/components/event/tickets/Tickets'
import EventContext from 'gatsby-theme-psg/src/components/EventContext'
import Content from 'gatsby-theme-psg/src/components/Content'
import { MdArrowForward } from 'react-icons/md'
import Img from "gatsby-image"

export const IndexPageTemplate = ({ images, logo }) => {
  let normalizedProps = logo
  if (normalizedProps.fluid && normalizedProps.fluid.presentationWidth) {
    normalizedProps = {
      ...normalizedProps,
      style: {
        ...(normalizedProps.style || {}),
        maxWidth: normalizedProps.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }
  return (
    <React.Fragment>
      <Header background='rgba(0,0,0,0.5)'>
        <Img {...normalizedProps} fluid={logo.fluid} alt='logo'
          className="logo clearfix"
        />
        <div className="my-3">
          <EventContainer />
        </div>
        <EventContext.Consumer>
          {({ state }) => (
            <div className="event">
              <span className="h2">
                {state.event.frontmatter.location} {state.event.frontmatter.status === 'Uitverkocht' &&
                  <span className="text-danger">[Uitverkocht]</span>
                }
              </span>
              <span className="h3">
                {state.event.frontmatter.dateLong}
              </span>
              <Content content={state.event.html} className="lead" />
              <Link to='/tickets' className="btn btn-tickets">Koop je tickets <MdArrowForward size={32} /></Link>
            </div>
          )}
        </EventContext.Consumer>
      </Header>
      <Section
        title="Artiesten"
        className="acts"
        link="/lineup"
        linkName="Volledige line-up"
      >
        <Lineup numItems={5} />
      </Section>
      <section className="section-tickets">
        <div className="bg-primary" style={{ paddingBottom: '100px' }}>
          <header className="py-5">
            <Heading title="Tickets" />
          </header>
        </div>
        <div className="container" style={{ marginTop: '-100px' }}>
          <Tickets />
        </div>
      </section>
      <Section title="Impressie" className="impressie">
        <div className="d-none d-md-block">
          <Gallery images={images} imagesPerPage="3" />
        </div>
        <div className="d-block d-md-none">
          <Gallery images={images} imagesPerPage="1" />
        </div>
      </Section>
    </React.Fragment>
  )
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
      <IndexPageTemplate images={post.frontmatter.images} logo={data.file.childImageSharp} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 360, quality: 90) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        description
        images {
          image {
            childImageSharp {
              fluid(maxWidth: 900, maxHeight: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`
